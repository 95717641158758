        import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import emailjs from 'emailjs-com';  //import emailjs
import {serviceId} from "./KeysEmailJS";
import {templateId} from "./KeysEmailJS";
import {publicKey} from "./KeysEmailJS";
import './contactus.css';
import ImgContactUs from "../../Statics/Img/Walkermill.jpg";

const Contactus = () => {
    const {register, handleSubmit, formState: {errors}, reset, watch} = useForm({
        defaultValues: {
            Name: "",
            Company: "",
            Cellphone: "",
            Email: "",
            Service: "",
            Additional: ""
        }
    });
    const [message, setMessage] = useState(false);
    const service = watch('Service', '');
    //eslint-disable-next-line
    const [data, setData] = useState("");
    const onSubmit = (Data) => {
        setData(JSON.stringify(Data));
        emailjs.send(serviceId, templateId, Data, publicKey)
            .then((response) => {   // if the message is sent successfully
                console.log('SUCCESS!', response.status, response.text);    // console.log the status and text of the response
            }, (err) => {   // if the message is not sent successfully
                console.log('FAILED...', err);  // console.log the error
            });
        setMessage(true);
        reset();
        setTimeout(() => {
            setMessage(false);
        }, 5000);

    };


    return (
        <div>
            <Navbar/>
            <div id="Container_Contactus" className="card">
                {
                    message ?
                        <div id="Message_Contact_Us" className="body-text body-text-24">
                            <p>Thank you for contacting us, we will contact you as soon as possible.</p>
                        </div>
                        :
                        <>
                            <img src={ImgContactUs} alt=""/>
                            <form id="Section_Contact_Us" onSubmit={handleSubmit(onSubmit)}
                                  className="body-text body-text-24">
                                <div className="container-label-input-form-contact-us">
                                    <section className="section-left-form-contact-us">
                                        <label htmlFor="1">Name:</label>
                                        <input type="text" {...register("Name",
                                            {
                                                required: {value: true, message: "This field is required"},
                                                pattern: {
                                                    value: /^[a-zA-Z\s]*$/,
                                                    message: "This field only accept letters"
                                                }
                                            }
                                        )} placeholder="John"/>
                                        {errors.Name && <p id="Message_Error_Form_Contact_Us">{errors.Name.message}</p>}
                                    </section>
                                    <section className="section-right-form-contact-us">
                                        <label htmlFor="2">Company:</label>
                                        <input {...register("Company",
                                            {
                                                required: {value: true, message: "This field is required"},
                                                pattern: {
                                                    value: /^[a-zA-Z\s]*$/,
                                                    message: "This field only accept letters"
                                                }
                                            }
                                        )} type="text" placeholder="Total Civil Construction"/>
                                        {errors.Company &&
                                            <p id="Message_Error_Form_Contact_Us">{errors.Company.message}</p>}
                                    </section>
                                </div>
                                <div className="container-label-input-form-contact-us">
                                    <section className="section-left-form-contact-us">
                                        <label htmlFor="">Cellphone:</label>
                                        <input {...register("Cellphone",
                                            {
                                                required: {value: true, message: "This field is required"},
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "This field only accept numbers"
                                                }
                                            }
                                        )} type="text" placeholder="3014597484"/>
                                        {errors.Cellphone &&
                                            <p id="Message_Error_Form_Contact_Us">{errors.Cellphone.message}</p>}
                                    </section>
                                    <section className="section-right-form-contact-us">
                                        <label htmlFor="2">Email:</label>
                                        <input {...register("Email",
                                            {
                                                required: {value: true, message: "This field is required"},
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                                    message: "This field only accept email format"
                                                }
                                            }
                                        )} type="email" placeholder="john@gmail.com"/>
                                        {errors.Email &&
                                            <p id="Message_Error_Form_Contact_Us">{errors.Email.message}</p>}
                                    </section>
                                </div>
                                <div className="container-label-input-form-contact-us">
                                    <section className="section-left-form-contact-us">
                                        <label htmlFor="Type_Of_Service">Select your type of services:</label>
                                        <select {...register("Service", {required: true})} id="Type_Of_Service">
                                            <option value="">Select...</option>
                                            <option value="CLEARING AND DEMOLITION">CLEARING AND DEMOLITION</option>
                                            <option value="DISASTER RECOVERY">DISASTER RECOVERY</option>
                                            <option value="EARTHWORK">EARTHWORK</option>
                                            <option value="TRANSPORTATION INFRASTRUCTURE">TRANSPORTATION INFRASTRUCTURE
                                            </option>
                                            <option value="UTILITY INFRASTRUCTURE">UTILITY INFRASTRUCTURE</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        {errors.Service &&
                                            <p id="Message_Error_Form_Contact_Us">This field is required</p>}
                                        {service === 'Other' && (
                                            <>
                                                <label htmlFor="2">Provide the other type of service:</label>
                                                <input {...register("OtherService", {required: true})} type="text"
                                                       id="Text_Other_Requisition"/>
                                                {errors.OtherService &&
                                                    <p id="Message_Error_Form_Contact_Us">This field is required</p>}
                                            </>
                                        )}
                                    </section>
                                    <section className="section-right-form-contact-us">
                                        <label htmlFor="2">Provide additional relevant information:</label>
                                        <textarea {...register("Additional")}
                                                  id="Text_Area_Additional_Information"
                                                  cols="30" rows="5"></textarea>
                                    </section>
                                </div>
                                <section id="Privacy_Policy_Contact_Us">
                                    <input type="checkbox" {...register("PrivacyPolicy")} id="privacyPolicy"/>
                                    <label htmlFor="privacyPolicy">I agree to the terms <a href="/">privacy
                                        policy</a>.</label>
                                </section>
                                <button type="submit" className="primary-button">Sent</button>
                            </form>
                        </>
                }
            </div>
            <Footer/>
        </div>
    );
}

export default Contactus;