import {ComponentPreview, Previews} from '@react-buddy/ide-toolbox'
import {PaletteTree} from './palette'
import Careers from "../layouts/careers/careers";
import Navbar from "../components/navbar/navbar";
import Aboutus from "../layouts/aboutus/aboutus";

const ComponentPreviews = () => {
    return (
        <Previews palette={<PaletteTree/>}>
            <ComponentPreview path="/Careers">
                <Careers/>
            </ComponentPreview>
            <ComponentPreview path="/Navbar">
                <Navbar/>
            </ComponentPreview>
            <ComponentPreview path="/Aboutus">
                <Aboutus/>
            </ComponentPreview>
        </Previews>
    )
}

export default ComponentPreviews