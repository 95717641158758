import React, {useState, useEffect} from 'react';
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./services.css";
import {data} from "./dataImages";
import {MdNavigateNext, MdNavigateBefore} from "react-icons/md";

function Services(props) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const Cant = data?.length;
    const [attention, setAttention] = useState(false);

    const handleAttention = () => {
        setAttention(true);
        setTimeout(() => {
            setAttention(false);
        }, 15000);
    }

    useEffect(() => {
        // Si está en pausa, no ejecuta el cambio de slide automático
        if (!attention) {
            const slideTimer = setTimeout(() => {
                nextSlide();
            }, 5000); // Cambia el slide cada 5 segundos

            return () => clearTimeout(slideTimer); // Limpiar el timer
        }
        // eslint-disable-next-line
    }, [currentIndex, attention]);

    if (!Array.isArray(data) || Cant <= 0) return null;

    const nextSlide = () => {
        setOpacity(0); // Desvanecer la imagen actual
        setTimeout(() => {
            setCurrentIndex(currentIndex === Cant - 1 ? 0 : currentIndex + 1);
            setOpacity(1); // Restaurar opacidad
        }, 500); // Esperar a que termine la transición antes de cambiar la imagen
    };

    const prevSlide = () => {
        setOpacity(0); // Desvanecer la imagen actual
        setTimeout(() => {
            setCurrentIndex(currentIndex === 0 ? Cant - 1 : currentIndex - 1);
            setOpacity(1); // Restaurar opacidad
        }, 500); // Esperar a que termine la transición antes de cambiar la imagen
    };

    return (
        <div id="Services_Container">
            <Navbar/>
            <div id="Carousel_Container">
                {data.map((item, index) => (
                    currentIndex === index ? (
                        <div key={item.id} id="Carousel">
                            <div className="section-1">
                                <div className="section-1-left" onClick={() => {
                                    handleAttention();
                                    prevSlide();
                                }}>
                                    <MdNavigateBefore/>
                                </div>

                                {index > 0 ? (
                                    <div className="img-left-carousel" id={`prev-${index - 1}`}>
                                        <img src={data[index - 1].imgUrl} alt={data[index - 1].title}/>
                                    </div>
                                ) : (
                                    <div className="img-left-carousel" id={`prev-${Cant - 1}`}>
                                        <img src={data[Cant - 1].imgUrl} alt={data[Cant - 1].title}/>
                                    </div>
                                )}

                                <img
                                    id="img-middle-carousel"
                                    src={item.imgUrl}
                                    alt={item.title}
                                    style={{opacity: opacity}} // Aplicar la opacidad
                                />

                                {index < (Cant - 1) ? (
                                    <div className="img-right-carousel" id={`next-${index + 1}`}>
                                        <img src={data[index + 1].imgUrl} alt={data[index + 1].title}/>
                                    </div>
                                ) : (
                                    <div className="img-right-carousel" id={`next-0`}>
                                        <img src={data[0].imgUrl} alt={data[0].title}/>
                                    </div>
                                )}

                                <div className="section-1-right" onClick={() => {
                                    handleAttention();
                                    nextSlide();
                                }}>
                                    <MdNavigateNext/>
                                </div>
                            </div>
                            <div className="section-2">
                                <h1 className="body-text body-text-40">{item.title}</h1>
                                <p className="body-text body-text-24">{item.description}</p>
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
            <Footer/>
        </div>
    );
}

export default Services;
