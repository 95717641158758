import React, {useState, useCallback} from 'react';
import './careers.css';
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import JobsData from "../../bd/Job.json";
import BenefitsData from "../../bd/benefits.json";

const JobDetails = ({job}) => (
    <div id="info-type-careers">
        <h1 className="body-text body-text-40">Description</h1>
        <p className="body-text body-text-24">{job.description}</p>
        <h1 className="body-text body-text-40">Location</h1>
        {job.location.map((loc, index) => (
            <p key={index} className="body-text body-text-24">{loc.name}</p>
        ))}
        <h1 className="body-text body-text-40">Benefits</h1>
        <div id="Container_Benefits_Careers" className="body-text body-text-24">
            {
                BenefitsData.Benefits.map((benefit, index) => (
                    <React.Fragment key={index}>
                        <ul>
                            <li>{benefit.name}
                                <ul>
                                    {benefit.Detail_benefits.map((detail, index) => (
                                        <React.Fragment key={index}>
                                            <li>{detail.name}</li>
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </React.Fragment>
                ))
            }
        </div>
    </div>
);

const JobCard = ({job, index, handleType, handleJobs, type, typeKey}) => (
    <button className="type-careers card" key={index}
            onClick={() => {
                if (window.innerWidth <= 768) {
                    handleType(index);
                    handleJobs(job);
                } else {
                    handleJobs(job);
                }
            }}>
        <h1>
            {job.name}
        </h1>
        {type && typeKey === index && (
            <div
                id="Type_Of_Careers_Phone"
            >
                <div id="Container_Img_Type_Career">
                    <img src={require(`../../${job.img}`)} alt={job.name}/>
                </div>
                <JobDetails job={job}/>
            </div>
        )}
    </button>
);

function Careers() {
    const [type, setType] = useState(false);
    const [typeKey, setTypeKey] = useState(0);
    const [selectedJob, setSelectedJob] = useState(null);

    const handleType = useCallback((key) => {
        if (type && typeKey === key) {
            document.getElementById("Section_Left_Careers").classList.add("hide");
            setTimeout(() => {
                document.getElementById("Section_Left_Careers").classList.remove("hide");
                setType(false);
            }, 500);
        } else {
            setTypeKey(key);
            setType(true);
        }
    }, [type, typeKey]);

    const handleJobs = useCallback((job) => {
        if (selectedJob === job) {
            setSelectedJob(job);
        } else {
            document.getElementById("Section_Right_Careers").classList.add("hide");
            setTimeout(() => {
                document.getElementById("Section_Right_Careers").classList.remove("hide");
                setSelectedJob(job);
            }, 500);
        }
    }, [selectedJob]);


    return (
        <div id="Container_Careers" className="w-full">
            <Navbar/>
            <section id="Container_Type_Careers" className="card">
                <section id="Section_Left_Careers" className="body-text body-text-24">
                    {JobsData.Jobs.map((job, index) => (
                        <JobCard
                            key={index}
                            job={job}
                            index={index}
                            handleType={handleType}
                            handleJobs={handleJobs}
                            type={type}
                            typeKey={typeKey}
                        />
                    ))}
                </section>
                <section id="Section_Right_Careers">
                    {selectedJob ? (
                        <>
                            <img src={require(`../../${selectedJob.img}`)} alt={selectedJob.name}/>
                            <JobDetails job={selectedJob}/>
                        </>
                    ) : (
                        <img src={require(`../../Statics/Img/Logo Total/Entrega_Logo_Total_M.png`)}
                             alt="Logo Total Blue" style={{margin: "auto"}}/>
                    )}
                </section>
            </section>
            <Footer/>
        </div>
    );
}

export default Careers;