export const Info = [
    {
        Age: 1980,
        Title: "Born in New York",
        Description: "I was born in New York City, New York, USA."
    },
    {
        Age: 1990,
        Title: "Moved to Chicago",
        Description: "My family moved to Chicago, Illinois, USA."
    },
    {
        Age: 2000,
        Title: "Graduated from University",
        Description: "I graduated from the University of Chicago."
    },
    {
        Age: 2010,
        Title: "Started my first job",
        Description: "I started my first job at a company called XYZ."
    },
    {
        Age: 2020,
        Title: "Started my own company",
        Description: "I started my own company called ABC."
    },
    {
        Age: 2030,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    },
    {
        Age: 2040,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    },
    {
        Age: 2050,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    },
    {
        Age: 2060,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    },
    {
        Age: 2070,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    },
    {
        Age: 2080,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    },
    {
        Age: 2090,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    },
    {
        Age: 2100,
        Title: "Future",
        Description: "I plan to retire and travel the world."
    }
]
