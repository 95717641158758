import React from 'react';
import ImageCard from "../../Statics/Img/slider1.jpg";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./projects.css";


function projects(props) {
    return (
        <div id="Container_Projects" className="w-full h-full">
            <Navbar/>
            <section id="Container_filters" className="section-container-projects">
                <button className="filters-container"><p className="body-text body-text-40">Filter</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" fill="none">
                        <path d="M30 10.5L16 26.5L2 10.5H30Z" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <button className="filters-container"><p className="body-text body-text-40">Filter</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" fill="none">
                        <path d="M30 10.5L16 26.5L2 10.5H30Z" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <button className="filters-container"><p className="body-text body-text-40">Filter</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" fill="none">
                        <path d="M30 10.5L16 26.5L2 10.5H30Z" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <button className="filters-container"><p className="body-text body-text-40">Filter</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" fill="none">
                        <path d="M30 10.5L16 26.5L2 10.5H30Z" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <button className="filters-container"><p className="body-text body-text-40">Filter</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" fill="none">
                        <path d="M30 10.5L16 26.5L2 10.5H30Z" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>

            </section>
            <section id="Container_Cards_Projects" className="section-container-projects">
                <div className="card-container-projects card">
                    <img src={ImageCard} alt="card" className="card"/>
                    <h1 className="body-text body-text-40">Name of project</h1>
                    <p className="body-text body-text-24">Description of project</p>
                </div>
                <div className="card-container-projects card">
                    <img src={ImageCard} alt="card" className="card"/>
                    <h1 className="body-text body-text-40">Name of project</h1>
                    <p className="body-text body-text-24">Description of project</p>
                </div>
                <div className="card-container-projects card">
                    <img src={ImageCard} alt="card" className="card"/>
                    <h1 className="body-text body-text-40">Name of project</h1>
                    <p className="body-text body-text-24">Description of project</p>
                </div>
                <div className="card-container-projects card">
                    <img src={ImageCard} alt="card" className="card"/>
                    <h1 className="body-text body-text-40">Name of project</h1>
                    <p className="body-text body-text-24">Description of project</p>
                </div>
                <div className="card-container-projects card">
                    <img src={ImageCard} alt="card" className="card"/>
                    <h1 className="body-text body-text-40">Name of project</h1>
                    <p className="body-text body-text-24">Description of project</p>
                </div>
                <div className="card-container-projects card">
                    <img src={ImageCard} alt="card" className="card"/>
                    <h1 className="body-text body-text-40">Name of project</h1>
                    <p className="body-text body-text-24">Description of project</p>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export default projects;