import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import './App.css';
import Services from './layouts/services/services';
import Aboutus from './layouts/aboutus/aboutus';
import Projects from './layouts/projects/projects';
import Careers from './layouts/careers/careers';
import Home from './layouts/home/home';
import Contactus from "./layouts/contactus/contactus";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contactus" element={<Contactus />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

