import React, {useEffect} from 'react';
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import "./aboutus.css";
import PersonsWorking from "../../Statics/Img/Home.jpg";
// import {Info} from "./Info";

function Aboutus() {
    useEffect(() => {
        const handleScroll = () => {
            const scroll = document.documentElement.scrollTop;
            const items = document.querySelectorAll(".timeline-item");
            items.forEach(elem => {
                if (elem.offsetTop - window.innerHeight / 2 < scroll) {
                    elem.classList.remove('hide');
                    elem.classList.add('show');
                } else {
                    elem.classList.remove('show');
                    elem.classList.add('hide');
                }
            });
        };

        const items = document.querySelectorAll(".timeline-item");
        items.forEach(elemento => {
            if (elemento.offsetTop < 300) {
                elemento.classList.add('show');
            }
        });

        window.addEventListener("scroll", handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div id="Container_Aboutus" className="w-full h-full">
            <Navbar/>
            <section id="Section_Aboutus_1" className="section-aboutus">
                <section>
                    <h1 className="body-text body-text-80">Mission</h1>
                    <p className="body-text body-text-40">With over two decades of experience in civil works, we’ve
                        built a team of seasoned professionals who specialize in constructing roads, bridges, and
                        buildings. Our mission is to deliver top-notch construction services that not only meet but
                        exceed our clients’ expectations. We’re committed to timely project delivery within budget, all
                        while maintaining the highest safety and quality standards. Reach out to us today to learn more
                        about our services and how we can assist with your next project.</p>
                </section>
                <img src={PersonsWorking} alt="Persons Working in Total Civil" className="card"/>
            </section>
            <Footer/>
        </div>
    );
}

export default Aboutus;
