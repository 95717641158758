import React from 'react';
import {useEffect, useState} from 'react';
import './navbar.css'
import TotalLogo from '../../Statics/Img/Logo Total/Entrega_Logo_Total_CB.png'
import TotalLogoSmall from '../../Statics/Img/Logo Total/Entrega_Logo_Total_CC.png'
// import AvatarAna from '../../Statics/Img/myAvatar.svg'

const Navbar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [menu, setMenu] = useState(false);
    // const [bot, setBot] = useState(false);
    // const handleActiveBot = () => {
    //     if (bot === true) {
    //         document.getElementById('Button_Chat_Bot_Dropdown_Contactus').classList.add('hide');
    //         document.getElementById('Button_Chat_Bot_Dropdown_Chat').classList.add('hide');
    //         setTimeout(() => {
    //             document.getElementById('Button_Chat_Bot_Dropdown_Contactus').classList.remove('hide');
    //             document.getElementById('Button_Chat_Bot_Dropdown_Chat').classList.remove('hide');
    //             setBot(false);
    //         }, 200);
    //     } else {
    //         setBot(true);
    //     }
    // }

    const handleRoute = (route) => {
        switch (route) {
            case 'Services':
                return () => {
                    window.location.href = '/Services';
                }
            case 'Projects':
                return () => {
                    window.location.href = '/Projects';
                }
            case 'About Us':
                return () => {
                    window.location.href = '/AboutUs';
                }
            case 'Careers':
                return () => {
                    window.location.href = '/Careers';
                }
            case 'contactus':
                return () => {
                    window.location.href = '/ContactUs';
                }
            default:
                return () => {
                    window.location.href = '/';
                }
        }
    };
    const handleMenu = () => {
        if (menu === true) {
            document.getElementById('Navbar_Dropdown').classList.add('hide');
            setTimeout(() => {
                document.getElementById('Navbar_Dropdown').classList.remove('hide');
                setMenu(false);
            }, 500);
        } else {
            setMenu(true);

        }
    }
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <div id="Container_Navbar" className="shadow-normal w-full">
                {windowWidth > 425 ?
                    <img id="Logo_Total_Navbar" src={TotalLogo} alt="Logo Of Total Civil Construction"
                         className="w-1/3" onClick={handleRoute('/')}/>
                    :
                    <img id="Logo_Total_Navbar" src={TotalLogoSmall} alt="Logo Of Total Civil Construction Small"
                         className="w-1/3" onClick={handleRoute('/')}/>

                }
                {menu ? '' :
                    <div id="Container_Buttons_Navbar_Top" className="w-1/3">
                        <h1 className="button-navbar-top body-text body-text-40 w-1/3"
                            onClick={handleRoute('Services')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="4dvh" viewBox="0 0 34 34"
                                 fill="none">
                                <path
                                    d="M29.2188 3.71875H4.78125C4.19355 3.71875 3.71875 4.19355 3.71875 4.78125V29.2188C3.71875 29.8064 4.19355 30.2812 4.78125 30.2812H29.2188C29.8064 30.2812 30.2812 29.8064 30.2812 29.2188V4.78125C30.2812 4.19355 29.8064 3.71875 29.2188 3.71875ZM12.2188 24.7031C12.2188 24.8492 12.0992 24.9688 11.9531 24.9688H9.29688C9.15078 24.9688 9.03125 24.8492 9.03125 24.7031V9.29688C9.03125 9.15078 9.15078 9.03125 9.29688 9.03125H11.9531C12.0992 9.03125 12.2188 9.15078 12.2188 9.29688V24.7031ZM18.5938 15.4062C18.5938 15.5523 18.4742 15.6719 18.3281 15.6719H15.6719C15.5258 15.6719 15.4062 15.5523 15.4062 15.4062V9.29688C15.4062 9.15078 15.5258 9.03125 15.6719 9.03125H18.3281C18.4742 9.03125 18.5938 9.15078 18.5938 9.29688V15.4062ZM24.9688 17.7969C24.9688 17.943 24.8492 18.0625 24.7031 18.0625H22.0469C21.9008 18.0625 21.7812 17.943 21.7812 17.7969V9.29688C21.7812 9.15078 21.9008 9.03125 22.0469 9.03125H24.7031C24.8492 9.03125 24.9688 9.15078 24.9688 9.29688V17.7969Z"
                                    fill="#0A0141"/>
                            </svg>
                            {windowWidth > 425 ? 'Services' : ''}
                        </h1>
                        {/*<h1 className="button-navbar-top body-text body-text-40 w-1/3"*/}
                        {/*    onClick={handleRoute('Projects')}>*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="4dvh" viewBox="0 0 34 34"*/}
                        {/*         fill="none">*/}
                        {/*        <path*/}
                        {/*            d="M2.83337 28.3333V19.8333H9.91671V21.25H12.75V19.8333H21.25V21.25H24.0834V19.8333H31.1667V28.3333H2.83337ZM3.43546 17L7.11879 8.5H9.91671V7.08333C9.91671 6.30417 10.1944 5.63739 10.7497 5.083C11.305 4.52861 11.9718 4.25094 12.75 4.25H21.25C22.0292 4.25 22.6965 4.52767 23.2518 5.083C23.8071 5.63833 24.0843 6.30511 24.0834 7.08333V8.5H26.8813L30.5646 17H24.0834V15.5833H21.25V17H12.75V15.5833H9.91671V17H3.43546ZM12.75 8.5H21.25V7.08333H12.75V8.5Z"*/}
                        {/*            fill="#0A0141"/>*/}
                        {/*    </svg>*/}
                        {/*    {windowWidth > 425 ? 'Projects' : ''}*/}
                        {/*</h1>*/}
                        <h1 className="button-navbar-top body-text body-text-40 w-1/3"
                            onClick={handleRoute('About Us')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="4dvh" viewBox="0 0 34 34"
                                 fill="none">
                                <path
                                    d="M18.4167 12.75H15.5834V9.91667H18.4167M18.4167 24.0833H15.5834V15.5833H18.4167M17 2.83333C15.1396 2.83333 13.2975 3.19976 11.5787 3.91171C9.85991 4.62365 8.29819 5.66716 6.98269 6.98265C4.32593 9.63942 2.83337 13.2428 2.83337 17C2.83337 20.7572 4.32593 24.3606 6.98269 27.0173C8.29819 28.3328 9.85991 29.3764 11.5787 30.0883C13.2975 30.8002 15.1396 31.1667 17 31.1667C20.7573 31.1667 24.3606 29.6741 27.0174 27.0173C29.6742 24.3606 31.1667 20.7572 31.1667 17C31.1667 15.1396 30.8003 13.2974 30.0883 11.5786C29.3764 9.85987 28.3329 8.29815 27.0174 6.98265C25.7019 5.66716 24.1402 4.62365 22.4214 3.91171C20.7026 3.19976 18.8604 2.83333 17 2.83333Z"
                                    fill="#0A0141"/>
                            </svg>
                            {windowWidth > 425 ? 'About Us' : ''}
                        </h1>
                    </div>
                }
                <div id="Icon_Menu_Dropdown_Navbar_Top" className="w-fit" onClick={handleMenu}>
                    {menu ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 50 50"
                             fill="none">
                            <path
                                d="M25 4.6875C13.7822 4.6875 4.6875 13.7822 4.6875 25C4.6875 36.2178 13.7822 45.3125 25 45.3125C36.2178 45.3125 45.3125 36.2178 45.3125 25C45.3125 13.7822 36.2178 4.6875 25 4.6875ZM28.4482 33.2705C28.5994 33.4142 28.7204 33.5866 28.8038 33.7777C28.8873 33.9688 28.9317 34.1747 28.9344 34.3833C28.9371 34.5918 28.898 34.7988 28.8194 34.992C28.7408 35.1852 28.6244 35.3607 28.4769 35.5081C28.3294 35.6556 28.1539 35.7721 27.9607 35.8506C27.7675 35.9292 27.5606 35.9683 27.352 35.9657C27.1435 35.963 26.9376 35.9186 26.7465 35.8351C26.5554 35.7516 26.3829 35.6307 26.2393 35.4795L16.8643 26.1045C16.5715 25.8115 16.407 25.4142 16.407 25C16.407 24.5858 16.5715 24.1885 16.8643 23.8955L26.2393 14.5205C26.5347 14.2399 26.928 14.0857 27.3355 14.0909C27.7429 14.0961 28.1322 14.2603 28.4203 14.5484C28.7084 14.8366 28.8726 15.2258 28.8778 15.6333C28.8831 16.0407 28.7289 16.4341 28.4482 16.7295L20.1787 25L28.4482 33.2705Z"
                                fill="#0A0141"/>
                        </svg>

                        :

                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"
                             fill="none">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M17 20C15.8954 20 15 20.8954 15 22V28C15 29.1046 15.8954 30 17 30H83C84.1046 30 85 29.1046 85 28V22C85 20.8954 84.1046 20 83 20H17ZM15 47C15 45.8954 15.8954 45 17 45H83C84.1046 45 85 45.8954 85 47V53C85 54.1046 84.1046 55 83 55H17C15.8954 55 15 54.1046 15 53V47ZM15 72C15 70.8954 15.8954 70 17 70H83C84.1046 70 85 70.8954 85 72V78C85 79.1046 84.1046 80 83 80H17C15.8954 80 15 79.1046 15 78V72Z"
                                  fill="#0A0141"/>
                        </svg>
                    }

                </div>
            </div>
            {menu ?
                <div id="Navbar_Dropdown" className="w-1/3">
                    <h1 className="body-text body-text-40 w-full" onClick={handleRoute('Services')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="4dvh" viewBox="0 0 34 34"
                             fill="none">
                            <path
                                d="M29.2188 3.71875H4.78125C4.19355 3.71875 3.71875 4.19355 3.71875 4.78125V29.2188C3.71875 29.8064 4.19355 30.2812 4.78125 30.2812H29.2188C29.8064 30.2812 30.2812 29.8064 30.2812 29.2188V4.78125C30.2812 4.19355 29.8064 3.71875 29.2188 3.71875ZM12.2188 24.7031C12.2188 24.8492 12.0992 24.9688 11.9531 24.9688H9.29688C9.15078 24.9688 9.03125 24.8492 9.03125 24.7031V9.29688C9.03125 9.15078 9.15078 9.03125 9.29688 9.03125H11.9531C12.0992 9.03125 12.2188 9.15078 12.2188 9.29688V24.7031ZM18.5938 15.4062C18.5938 15.5523 18.4742 15.6719 18.3281 15.6719H15.6719C15.5258 15.6719 15.4062 15.5523 15.4062 15.4062V9.29688C15.4062 9.15078 15.5258 9.03125 15.6719 9.03125H18.3281C18.4742 9.03125 18.5938 9.15078 18.5938 9.29688V15.4062ZM24.9688 17.7969C24.9688 17.943 24.8492 18.0625 24.7031 18.0625H22.0469C21.9008 18.0625 21.7812 17.943 21.7812 17.7969V9.29688C21.7812 9.15078 21.9008 9.03125 22.0469 9.03125H24.7031C24.8492 9.03125 24.9688 9.15078 24.9688 9.29688V17.7969Z"
                                fill="#0A0141"/>
                        </svg>
                        Services
                    </h1>
                    {/*<h1 className="body-text body-text-40 w-full" onClick={handleRoute('Projects')}>*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="4dvh" viewBox="0 0 34 34"*/}
                    {/*         fill="none">*/}
                    {/*        <path*/}
                    {/*            d="M2.83337 28.3333V19.8333H9.91671V21.25H12.75V19.8333H21.25V21.25H24.0834V19.8333H31.1667V28.3333H2.83337ZM3.43546 17L7.11879 8.5H9.91671V7.08333C9.91671 6.30417 10.1944 5.63739 10.7497 5.083C11.305 4.52861 11.9718 4.25094 12.75 4.25H21.25C22.0292 4.25 22.6965 4.52767 23.2518 5.083C23.8071 5.63833 24.0843 6.30511 24.0834 7.08333V8.5H26.8813L30.5646 17H24.0834V15.5833H21.25V17H12.75V15.5833H9.91671V17H3.43546ZM12.75 8.5H21.25V7.08333H12.75V8.5Z"*/}
                    {/*            fill="#0A0141"/>*/}
                    {/*    </svg>*/}
                    {/*    Projects*/}
                    {/*</h1>*/}
                    <h1 className="body-text body-text-40 w-full" onClick={handleRoute('About Us')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="4dvh" viewBox="0 0 34 34"
                             fill="none">
                            <path
                                d="M18.4167 12.75H15.5834V9.91667H18.4167M18.4167 24.0833H15.5834V15.5833H18.4167M17 2.83333C15.1396 2.83333 13.2975 3.19976 11.5787 3.91171C9.85991 4.62365 8.29819 5.66716 6.98269 6.98265C4.32593 9.63942 2.83337 13.2428 2.83337 17C2.83337 20.7572 4.32593 24.3606 6.98269 27.0173C8.29819 28.3328 9.85991 29.3764 11.5787 30.0883C13.2975 30.8002 15.1396 31.1667 17 31.1667C20.7573 31.1667 24.3606 29.6741 27.0174 27.0173C29.6742 24.3606 31.1667 20.7572 31.1667 17C31.1667 15.1396 30.8003 13.2974 30.0883 11.5786C29.3764 9.85987 28.3329 8.29815 27.0174 6.98265C25.7019 5.66716 24.1402 4.62365 22.4214 3.91171C20.7026 3.19976 18.8604 2.83333 17 2.83333Z"
                                fill="#0A0141"/>
                        </svg>
                        About Us
                    </h1>
                    <h1 className="body-text body-text-40 w-full" onClick={handleRoute('Careers')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                            <path
                                d="M5.66671 29.7953C4.88754 29.7953 4.22076 29.5181 3.66637 28.9637C3.11199 28.4094 2.83432 27.7421 2.83337 26.962V11.3787C2.83337 10.5995 3.11104 9.93272 3.66637 9.37833C4.22171 8.82394 4.88849 8.54627 5.66671 8.54533H11.3334V5.712C11.3334 4.93283 11.611 4.26605 12.1664 3.71166C12.7217 3.15727 13.3885 2.87961 14.1667 2.87866H19.8334C20.6125 2.87866 21.2798 3.15633 21.8351 3.71166C22.3905 4.267 22.6677 4.93377 22.6667 5.712V8.54533H28.3334C29.1125 8.54533 29.7798 8.823 30.3351 9.37833C30.8905 9.93366 31.1677 10.6004 31.1667 11.3787V26.962C31.1667 27.7412 30.8895 28.4084 30.3351 28.9637C29.7807 29.5191 29.1135 29.7963 28.3334 29.7953H5.66671ZM14.1667 8.54533H19.8334V5.712H14.1667V8.54533Z"
                                fill="#0A0141"/>
                        </svg>
                        Careers
                    </h1>
                    <h1 className="body-text body-text-40 w-full" onClick={handleRoute('contactus')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                            <path
                                d="M5.66671 29.7953C4.88754 29.7953 4.22076 29.5181 3.66637 28.9637C3.11199 28.4094 2.83432 27.7421 2.83337 26.962V11.3787C2.83337 10.5995 3.11104 9.93272 3.66637 9.37833C4.22171 8.82394 4.88849 8.54627 5.66671 8.54533H11.3334V5.712C11.3334 4.93283 11.611 4.26605 12.1664 3.71166C12.7217 3.15727 13.3885 2.87961 14.1667 2.87866H19.8334C20.6125 2.87866 21.2798 3.15633 21.8351 3.71166C22.3905 4.267 22.6677 4.93377 22.6667 5.712V8.54533H28.3334C29.1125 8.54533 29.7798 8.823 30.3351 9.37833C30.8905 9.93366 31.1677 10.6004 31.1667 11.3787V26.962C31.1667 27.7412 30.8895 28.4084 30.3351 28.9637C29.7807 29.5191 29.1135 29.7963 28.3334 29.7953H5.66671ZM14.1667 8.54533H19.8334V5.712H14.1667V8.54533Z"
                                fill="#0A0141"/>
                        </svg>
                        Contact us
                    </h1>
                </div>
                : ''}
            {/*<div id="Container_Chat_Bot_Ana">*/}
            {/*    <div id="Chat_Bot_Ana" onClick={handleActiveBot}>*/}
            {/*        <img src={AvatarAna} alt="Avatar Ana"/>*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        bot ?*/}
            {/*            <div id="Chat_Bot_Ana_Dropdown">*/}
            {/*                <button id="Button_Chat_Bot_Dropdown_Chat" className="button-chat-bot">*/}
            {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="3vw" height="3vw" viewBox="0 0 70 70"*/}
            {/*                         fill="none">*/}
            {/*                        <path*/}
            {/*                            d="M58.333 5.83331H11.6663C8.45801 5.83331 5.86217 8.45831 5.86217 11.6666L5.83301 64.1666L17.4997 52.5H58.333C61.5413 52.5 64.1663 49.875 64.1663 46.6666V11.6666C64.1663 8.45831 61.5413 5.83331 58.333 5.83331ZM20.4163 26.25H49.583C51.1872 26.25 52.4997 27.5625 52.4997 29.1666C52.4997 30.7708 51.1872 32.0833 49.583 32.0833H20.4163C18.8122 32.0833 17.4997 30.7708 17.4997 29.1666C17.4997 27.5625 18.8122 26.25 20.4163 26.25ZM37.9163 40.8333H20.4163C18.8122 40.8333 17.4997 39.5208 17.4997 37.9166C17.4997 36.3125 18.8122 35 20.4163 35H37.9163C39.5205 35 40.833 36.3125 40.833 37.9166C40.833 39.5208 39.5205 40.8333 37.9163 40.8333ZM49.583 23.3333H20.4163C18.8122 23.3333 17.4997 22.0208 17.4997 20.4166C17.4997 18.8125 18.8122 17.5 20.4163 17.5H49.583C51.1872 17.5 52.4997 18.8125 52.4997 20.4166C52.4997 22.0208 51.1872 23.3333 49.583 23.3333Z"*/}
            {/*                            fill="white"/>*/}
            {/*                    </svg>*/}
            {/*                </button>*/}
            {/*                <button id="Button_Chat_Bot_Dropdown_Contactus" className="button-chat-bot"*/}
            {/*                        onClick={handleRoute('contactus')}>*/}
            {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="3vw" height="3vw" viewBox="0 0 70 70"*/}
            {/*                         fill="none">*/}
            {/*                        <path*/}
            {/*                            d="M17.5 49.5833C17.5 43.75 29.1667 40.5417 35 40.5417C40.8333 40.5417 52.5 43.75 52.5 49.5833V52.5H17.5M43.75 26.25C43.75 28.5706 42.8281 30.7962 41.1872 32.4372C39.5462 34.0781 37.3206 35 35 35C32.6794 35 30.4538 34.0781 28.8128 32.4372C27.1719 30.7962 26.25 28.5706 26.25 26.25C26.25 23.9294 27.1719 21.7038 28.8128 20.0628C30.4538 18.4219 32.6794 17.5 35 17.5C37.3206 17.5 39.5462 18.4219 41.1872 20.0628C42.8281 21.7038 43.75 23.9294 43.75 26.25ZM8.75 14.5833V55.4167C8.75 56.9638 9.36458 58.4475 10.4585 59.5415C11.5525 60.6354 13.0362 61.25 14.5833 61.25H55.4167C56.9638 61.25 58.4475 60.6354 59.5415 59.5415C60.6354 58.4475 61.25 56.9638 61.25 55.4167V14.5833C61.25 13.0362 60.6354 11.5525 59.5415 10.4585C58.4475 9.36458 56.9638 8.75 55.4167 8.75H14.5833C13.0362 8.75 11.5525 9.36458 10.4585 10.4585C9.36458 11.5525 8.75 13.0362 8.75 14.5833Z"*/}
            {/*                            fill="white"/>*/}
            {/*                    </svg>*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*            : ''*/}
            {/*    }*/}
            {/*</div>*/}
        </>
    );
}

export default Navbar;