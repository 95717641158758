import slider1 from '../../Statics/Img/slider1.jpg';
import slider2 from '../../Statics/Img/01 _Title Page skid steers.jpg';
import slider3 from '../../Statics/Img/10 IMG_1028 wrappedup.JPG';
import slider4 from '../../Statics/Img/Walkermill 2.jpg';
import slider5 from '../../Statics/Img/Southern.jpg';


export const data = [
    {
        id: 1,title:"CLEARING AND DEMOLITION", imgUrl: slider1, description: "The assets of Total Civil Construction include a safety-trained labor force, highly skilled crew leaders, and a company-owned fleet of heavy equipment to tackle initial phase operations for clearing a job site.\n" +
            "\n" +
            "When present, existing structures are strategically demolished to contain contaminants and mitigate safety concerns. Resulting rubble and natural materials are tested for environmental and human hazard to determine appropriate disposal. When feasible, organic materials are dried and repurposed as alternative energy, or composted for soil amendment and re-forestation projects."
    },
    {
        id: 2,title: "DISASTER RECOVERY", imgUrl: slider2, description: "When disaster strikes, the first responses are for rescue and relief. The next level response? Recovery and Re-build. That is where Total Civil Construction provides the heavy lifting. Clearing debris, restoring surface stability, using 3-d modeling to re-engineer design and install conduits for wet and dry utilities, Total Civil Construction takes a strategic approach to restore communities and engineer solutions to mitigate or prevent future disasters.\n" +
            "\n" +
            "We deploy superintendents and project managers to provide professional services for major disasters in the continental US.\n" +
            "\n" +
            "We bring on the talent, the technology, and the tools to forge ahead with critical recovery operations."
    },
    {
        id: 3,title: "EARTHWORK", imgUrl: slider3, description: "\n" +
            "Total Civil Construction combines traditional methodology with 3-D technology to measure and calculate earthwork projects. Our value-engineering techniques have resulted in our reputation, qualification, and participation in some of the largest earthwork projects throughout the capital region.\n" +
            "\n" +
            "Total Civil Construction received the ABC [Associated Builders and Contractor's, Inc.] National Excellence in Construction Award for Sitework for successfully managing a project that included the removal of 2.4 million cubic yards of soil within a project's 1-year timeline; and we did this while preserving historically significant buildings."
    },
    {
        id: 4, title: "TRANSPORTATION INFRASTRUCTURE", imgUrl: slider4, description: "Total Civil Construction has constructed hundreds of miles of roadway infrastructure, steel span bridges, and arch culverts for both private developers and government projects at the federal, state, and local level. Because these projects involve public safety, the jobs are necessarily complex and often involve coordination and installation of multiple subterranean utility networks. We engage subcontractors for trades beyond our self-performed areas of expertise. Over time, Total Civil Construction has curated an extensive network of subcontractors who meet our standards for both quality and timely deliverables."
    },
    {
        id:5, title: "UTILITY INFRASTRUCTURE" ,
        imgUrl: slider5, description: "Specializing in wet utilities, Total Civil Construction utility foremen, pipe-layers, and conduit-installers have developed their expertise through a combination of experience, training, and continuing education on improved methods and materials. Many of our crew leaders began as young adults hired for general labor, demonstrated initiative and a strong work ethic during their time with the company, and have advanced through the ranks.\n" +
            "\n" +
            "Total Civil Construction believes that support for training and tuition is not a business expense; it is an investment in sustaining a culture of excellence."
    }

];

