// Import React and the useEffect hook from the React library.
import React from 'react';

// Import the Navbar component from the specified path.
import Navbar from "../../components/navbar/navbar";

// Import the Footer component from the specified path.
import Footer from "../../components/footer/footer";

// Import the CSS file to apply styles to this component.
import "./home.css";

// Import the main image for the home page from the specified path.
import ImgHome from "../../Statics/Img/Suitland HS Phase 1 view S.jpg";

// Import the main image for the home page from the specified path.
import CardImg from "../../Statics/Img/20 Aerial Stormpod Pre-cast - WET UTILITIES.jpg";

// Define the functional component Home.
function Home() {

    // Return the JSX that defines the structure and content of the Home component.
    return (
        // Main container of the Home component with CSS classes for full width and height.
        <div id="Home" className="w-full h-full">
            {/* Render the Navbar component */}
            <Navbar/>
            {/* Container for the main video */}
            <div id="Background_Container">
                <img src={ImgHome} alt="ImgHome"/>
            </div>
            {/* Container for the main slogan */}
            <div id="Container_Slogan_Main_View" className="w-full body-text body-text-40">
                We don't do things right, we do them excellently!
            </div>
            {/* Container for general statistics */}
            <div id="Rounded_Div_General" className="w-full">
                <div className="rounded-div card">
                    <h1 className="body-text body-text-80">+50</h1>
                    <p className="body-text body-text-40">Closed Projects</p>
                </div>

                <div className="rounded-div card">
                    <h1 className="body-text body-text-80">+30</h1>
                    <p className="body-text body-text-40">Years in the Industry</p>
                </div>

                <div className="rounded-div card">
                    <h1 className="body-text body-text-80">+306</h1>
                    <p className="body-text body-text-40">Executed Amount</p>
                </div>
            </div>
            {/* Container for main sections */}
            <div id="Container_Sections_Main_View">
                <section id="Section_1_Main_View">
                    {/* Welcome text and company description */}
                    <p className="body-text body-text-30">Welcome to TCCE! With over two decades of experience in civil
                        works, we’ve built a team of
                        seasoned professionals who specialize in constructing roads, bridges, and buildings.
                        <br/><br/>
                        Our mission is to deliver top-notch construction services that not only meet but exceed our
                        clients’ expectations. We’re committed to timely project delivery within budget, all while
                        maintaining the highest safety and quality standards. Reach out to us today to learn more about
                        our services and how we can assist with your next project.
                    </p>
                </section>

                <section id="Section_2_Main_View">
                    {/* Container for the main image */}
                    <div className='Container-img-home'>
                        <img src={CardImg} alt="TCCE"/>
                    </div>
                </section>
            </div>
            {/* Render the Footer component */}
            <Footer/>
        </div> // END MAIN DIV
    );
}

// Export the Home component as the default export.
export default Home;